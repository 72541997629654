import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa"; // Importando ícones
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="social-icons">
        <a href="https://www.facebook.com/profile.php?id=100092488685792" target="_blank" rel="noreferrer" title="Facebook">
          <FaFacebookF />
        </a>
        {/* <a href="https://twitter.com" target="_blank" rel="noreferrer" title="Twitter">
          <FaTwitter />
        </a> */}
        <a href="https://www.instagram.com/capitus.br/" target="_blank" rel="noreferrer" title="Instagram">
          <FaInstagram />
        </a>
        <a href="https://www.linkedin.com/company/capitus-br/" target="_blank" rel="noreferrer" title="LinkedIn">
          <FaLinkedinIn />
        </a>
      </div>
      <div className="footer-links">
        <a href="/termos-de-uso">Termos de Uso </a>
        <a href="/politica-de-privacidade">Política de Privacidade</a>
      </div>
      <div className="footer-rights">
        <p>&copy; Capitus 2024 - Todos os direitos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
