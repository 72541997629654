import { useEffect } from "react";

const BrevoConversations = () => {
  useEffect(() => {
    // Adiciona o script do Brevo Conversations dinamicamente
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://conversations-widget.brevo.com/brevo-conversations.js";
    script.onload = () => {
      window.BrevoConversationsID = "6754def750ce21b779049681";
      if (window.BrevoConversations) {
        window.BrevoConversations();
      }
    };
    document.body.appendChild(script);

    return () => {
      // Remove o script quando o componente for desmontado
      document.body.removeChild(script);
    };
  }, []);

  return null; // Não há renderização de interface
};

export default BrevoConversations;
