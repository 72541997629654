import React from "react";
import "./PoliticaPrivacidade.css"; // Importa o estilo

const PoliticaPrivacidade = () => {
  return (
    <div className="politica-container">
      <h1>Política de Privacidade</h1>
      <p>
        Na <strong>Capitus</strong>, a proteção de seus dados é uma prioridade. Este documento
        descreve como coletamos, usamos e protegemos suas informações, em conformidade com a Lei
        Geral de Proteção de Dados (LGPD - Lei nº 13.709/2018).
      </p>

      <h2>1. Dados coletados</h2>
      <p>
        Coletamos apenas as informações necessárias para oferecer cotações e serviços financeiros:
      </p>
      <ul>
        <li>Nome completo</li>
        <li>CPF ou CNPJ</li>
        <li>Telefone</li>
        <li>E-mail</li>
        <li>Renda e valor solicitado</li>
      </ul>

      <h2>2. Finalidade</h2>
      <p>
        Utilizamos seus dados exclusivamente para:
      </p>
      <ul>
        <li>Realizar cotações de produtos financeiros (empréstimos, seguros, financiamentos);</li>
        <li>Entrar em contato para apresentar propostas ou esclarecer dúvidas;</li>
        <li>Cumprir exigências legais e regulatórias.</li>
      </ul>

      <h2>3. Compartilhamento</h2>
      <p>
        Seus dados serão compartilhados apenas com instituições financeiras, seguradoras ou parceiros
        diretamente envolvidos na análise e aprovação dos serviços solicitados. Não comercializamos
        ou divulgamos suas informações para outros fins.
      </p>

      <h2>4. Armazenamento e segurança</h2>
      <p>
        Seus dados são armazenados em ambiente seguro, com tecnologias de proteção contra acesso
        não autorizado. Informações sensíveis não são enviadas por e-mail e permanecem em servidores
        protegidos.
      </p>

      <h2>5. Retenção</h2>
      <p>
        Manteremos suas informações enquanto necessário para atender às finalidades informadas e às
        obrigações legais. Você pode solicitar a exclusão de seus dados a qualquer momento.
      </p>

      <h2>6. Direitos do usuário</h2>
      <p>Você tem direito de:</p>
      <ul>
        <li>Acessar suas informações;</li>
        <li>Corrigir dados incorretos;</li>
        <li>Excluir seus dados;</li>
        <li>Revogar consentimentos concedidos.</li>
      </ul>
      <p>
        Para exercer seus direitos, entre em contato pelos canais informados abaixo.
      </p>

      <h2>7. Contato</h2>
      <p>
        Para dúvidas ou solicitações sobre esta política, use os canais abaixo:
      </p>
      <ul>
        <li><strong>E-mail:</strong> privacidade@capitus.com.br</li>
        <li><strong>Telefone:</strong> (11) 93410-8166</li>
        <li><strong>WhatsApp:</strong> (21) 97838-5755</li>
      </ul>

      <h2>8. Nosso papel</h2>
      <p>
        A Capitus atua como correspondente bancário, intermediando cotações e aprovações junto a
        instituições financeiras e seguradoras. A decisão final sobre a aprovação de produtos é
        responsabilidade exclusiva dessas instituições.
      </p>

      <p className="ultima-atualizacao">
        <strong>Última atualização:</strong> 19 de novembro de 2024.
      </p>
    </div>
  );
};

export default PoliticaPrivacidade;
