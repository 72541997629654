import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { MdOutlineEmail } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa";

import Header from "../components/Header"; // Importação do Header
import "./Contact.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    consent: false,
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        "service_r9ciohd",
        "template_c3xdklg",
        formData,
        "E8RszPUuHa1xdpkxd"
      )
      .then(
        (response) => {
          setLoading(false);
          alert("E-mail enviado com sucesso!");
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            consent: false,
          });
        },
        (error) => {
          setLoading(false);
          alert("Erro ao enviar o e-mail. Tente novamente.");
          console.error("Erro no Email.js:", error);
        }
      );
  };

  // useEffect(() => {
  //   // Adiciona o script do Brevo Conversations dinamicamente
  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.src = "https://conversations-widget.brevo.com/brevo-conversations.js";
  //   script.onload = () => {
  //     window.BrevoConversationsID = "6754def750ce21b779049681";
  //     if (window.BrevoConversations) {
  //       window.BrevoConversations();
  //     }
  //   };
  //   document.body.appendChild(script);

  //   return () => {
  //     // Remove o script quando o componente for desmontado
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <>
      <Header /> {/* Renderizando o Header aqui */}
      <section id="contato">
        <h2>Envie sua mensagem</h2>
        <p>Escolha a melhor forma de nos contatar:</p>

        <div className="contact-info">
          <p>
            <MdOutlineEmail size="20" />
            <a href="mailto:contato@capitus.com.br">contato@capitus.com.br</a>

            <strong>
              <FaPhoneVolume size="20" />
            </strong>{" "}
            <a href="tel:+551132165000">(11) 93410-8166</a>
            <a href="tel:+552132165000">(21) 97838-5755</a>
          </p>
        </div>

        <form id="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nome</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder=""
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">E-mail</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder=""
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="telefone">Telefone</label>
            <input
              type="tel"
              id="telefone"
              name="phone"
              placeholder=""
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Assunto</label>
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder=""
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Mensagem</label>
            <textarea
              id="message"
              name="message"
              placeholder=""
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group consent">
            <label>
              <input
                type="checkbox"
                name="consent"
                checked={formData.consent}
                onChange={handleChange}
                required
              />
              Declaro que li e aceito a{" "}
              <a href="/politica-de-privacidade" target="_blank">
                Política de Privacidade.
              </a>
            </label>
          </div>

          <button className="btn-contato" type="submit" disabled={loading}>
            {loading ? "Enviando..." : "Enviar"}
          </button>
        </form>
      </section>
    </>
  );
};

export default ContactForm;