import React from "react";
import Header from "../components/Header"; // Certifique-se de que o caminho está correto
import "./LimpaNome.css"; // Certifique-se de que o CSS está no caminho certo

const LimpaNome = () => {
  return (
    <>
      <Header /> {/* Renderizando o componente Header */}
      <div className="hero-container">
  <div className="hero-image">
    <img src="/limpanome_mulher.jpg" alt="Com o Limpa Nome" />
    {/* Texto que será exibido sobre a imagem apenas no mobile */}
    <div className="mobile-title">Limpa Nome</div>
  </div>
  <div className="content">
  
    <h1 className="hero-title">Recupere seu nome e tranquilidade financeira! ✨</h1>
    
    <ul className="benefits">
    <li> Com o nosso serviço <strong>Limpa Nome</strong>, você tem:</li>
      <li>✅ Proteção garantida por no mínimo 12 meses contra os apontamentos eliminados;</li>
      <li>✅ Paz de espírito para focar no que realmente importa;</li>
      <li>✅ Restauração do Score, abrindo portas para novas oportunidades;</li>
      <li>✅ Exclusão rápida de apontamentos ou seu dinheiro de volta! </li>
            
    </ul>
    <p className="cta-text">Não deixe as restrições controlarem seu futuro.</p>
    <p className="cta-text">
     Entre em contato agora e transforme sua vida financeira hoje mesmo!
    </p>
    <a
      href="https://wa.me/5521978385755?text=Olá, gostaria de saber mais sobre o serviço Limpa Nome!"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button className="cta-button">Entre em Contato</button>
    </a>
  </div>
</div>

      {/* FAQ Visual */}
      <section className="faq">
        <h2>Perguntas Frequentes</h2>
        <details>
          <summary>O que é o Limpa Nome?</summary>
          <ul>
          <li>
            O <strong>Limpa Nome</strong> é um serviço especializado em remover restrições e apontamentos associados ao seu CPF ou CNPJ, ajudando você a recuperar sua tranquilidade financeira e abrir novas oportunidades no mercado. Nossa equipe de especialistas garante um processo rápido, seguro e transparente.
          </li>
          </ul>
        </details>

        <details>
          <summary>Para quem é o Limpa Nome?</summary>
          <p>O Limpa Nome é ideal para:</p>
          <ul>
            <li>✔️ Pessoas físicas com restrições no CPF, impedidas de realizar compras ou solicitar crédito.</li>
            <li>✔️ Empresas (CNPJ) que enfrentam dificuldades financeiras devido a apontamentos ou restrições comerciais.</li>
            <li>✔️ Quem busca reorganizar sua vida financeira, eliminando bloqueios e recuperando o controle.</li>
          </ul>
        </details>

        <details>
          <summary>Como funciona o Limpa Nome?</summary>
          <ol>
            <li>✅ Entre em contato conosco e informe sua situação financeira.</li>
            <li>✅ Nossos especialistas avaliam suas restrições e dívidas.</li>
            <li>✅ Damos entrada no processo e removemos os apontamentos do seu nome. </li>
            <li>✅ Nosso serviço é realizado por meio de ação judicial, permitindo-nos solicitar a remoção dos apontamentos nos seguntes órgãos de proteção ao crédito.</li>
            <li> SPC, Serasa, Boa Vista e  Cenprot.</li>
            <li>✅ Damos entrada no processo e removemos os apontamentos do seu nome. </li>
            <li>O serviço prestado contará com um contrato formal, assegurando clareza e segurança para ambas as partes.</li>
            
          </ol>
        </details>

        <details>
          <summary>Quanto tempo leva para limpar meu nome?</summary>
          <p>Em média, entre 30 e 45 dias, dependendo do caso.</p>
        </details>

        <details>
          <summary>Vocês atendem empresas (CNPJ)?</summary>
          <p>Sim, ajudamos tanto pessoas físicas quanto jurídicas.</p>
        </details>
        <details>
          <summary>A minha dívida será quitada?</summary>
          <p>Não. O serviço refere-se a exclusão dos órgãos de
proteção ao crédito e “não” a quitação das suas
dividas, vamos eliminar todas as restrições
possibilitando um novo acesso ao crédito.</p>
        </details>
          <details>
          <summary>O que acontece se eu tiver novas dívidas?</summary>
          <p>Como se trata de um processo jurídico enquanto o
processo esteja sendo julgado, não poderá ocorrer novas
negativações.</p>
        </details>
        </section>
    </>
  );
};

export default LimpaNome;
