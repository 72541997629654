import React from "react";
import "./Sobre.css"; // Importa os estilos específicos da página
import Header from "../components/Header"; // Importa o header se necessário
import LogoParceiros from "../components/LogoParceiros"; // Se você tiver este componente

const Sobre = () => {
  return (
    <>
      {/* Header */}
      <Header />

      {/* Conteúdo da Página Sobre */}
      <div className="sobre-container">
  <h1>Quem Somos</h1>
  <p>
    A <strong>Capitus Soluções Financeiras</strong> foi criada para oferecer soluções 
    estratégicas e personalizadas, sempre focadas em atender às demandas exclusivas de cada cliente. 
    Com mais de <strong>três décadas de experiência</strong> no setor bancário, acumulamos um 
    conhecimento sólido dos mercados financeiro e segurador, aliado a uma trajetória consistente 
    em diversas funções na área comercial e no segmento de alta renda.
  </p>

  <p>
    Sob a liderança de nossa fundadora, uma profissional com formação em <strong>Economia</strong>, 
    especializações em <strong>Gestão de Recursos Humanos</strong> e <strong>Negócios Bancários</strong>, 
    além de um <strong>MBA Executivo em ESG</strong>, a Capitus une excelência técnica a uma visão 
    estratégica. Certificações renomadas como <strong>CPA-20 </strong>  
     consolidam nosso compromisso com as melhores práticas do mercado, assegurando a entrega de soluções 
    de alto padrão.
  
  </p>

  <p>
    Nosso trabalho é guiado por valores como <strong>determinação</strong>, <strong>criatividade</strong>, 
    <strong> resiliência</strong>, <strong>diversidade</strong> e um forte 
    <strong>  compromisso com a qualidade</strong>. Acreditamos que relações baseadas na transparência e 
    na confiança mútua são fundamentais para construir parcerias duradouras e gerar resultados consistentes.
  </p>

  <p>
    Na <strong>Capitus</strong>, desenvolvemos soluções completas e personalizadas, fundamentadas em análises 
    detalhadas e estratégias eficazes. Nosso objetivo é atuar como um parceiro estratégico, auxiliando nossos 
    clientes a alcançar metas financeiras, promover crescimento sustentável e construir um futuro próspero.
  </p>

  <p>
    Estamos prontos para ser seu suporte confiável, oferecendo soluções inteligentes e alinhadas às suas 
    necessidades, no caminho para o sucesso financeiro.
  </p>
</div>




      {/* Seção de Parceiros (opcional) */}
      <LogoParceiros />
    </>
  );
};

export default Sobre;
