import React from "react";
import Header from "../components/Header"; // Componente global
import styled from "styled-components";

function SeguroResidencial() {
  const sections = [
    {
      title: "Coberturas Principais",
      content: (
        <>
          <p>Tenha a segurança de saber que seu lar está protegido contra diversos imprevistos:</p>
          <ul>
            <li>Incêndio, queda de raio, explosão, queda de aeronaves</li>
            <li>Roubo ou furto qualificado</li>
            <li>Danos elétricos</li>
            <li>Responsabilidade civil (Danos a terceiros)</li>
            <li>Vendaval, furacão, ciclone, tornado, granizo, neve e geada</li>
          </ul>
        </>
      ),
      image: "/residential_insurance_image02.jpg",
      alt: "Coberturas principais",
    },
    {
      title: "Coberturas Adicionais",
      content: (
        <>
          <p>Personalize seu seguro e adicione coberturas extras conforme sua necessidade:</p>
          <ul>
            <li>Moradia temporária</li>
            <li>Alagamento e inundação</li>
            <li>Vidros, mármores, granitos ou porcelanatos</li>
            <li>Ruptura de tubulações</li>
            <li>Equipamentos eletrônicos e eletrodomésticos</li>
          </ul>
        </>
      ),
      image: "/seg-res-coberturas-adicionais.jpg",
      alt: "Coberturas adicionais",
    },
    {
      title: "Assistências Básicas",
      content: (
        <>
          <p>Conte com suporte imediato para pequenos imprevistos no seu dia a dia:</p>
          <ul>
            <li>Chaveiro</li>
            <li>Encanador</li>
            <li>Eletricista</li>
            <li>Vidraceiro</li>
            <li>Desentupimento com maquinário</li>
            <li>Limpeza de caixa de gordura ou esgoto</li>
            <li>Fixação de antenas</li>
            <li>Mudança e guarda-móveis</li>
          </ul>
        </>
      ),
      image: "sos-assistencias-basicas.jpg",
      alt: "Assistências Básicas",
    },
    {
      title: "Assistência Pet 🐈🐶",
      content: (
        <>
          <p>Cuidado especial com seus animais de estimação:</p>
          <ul>
            <li>Transporte emergencial</li>
            <li>Organização de envio de ração em domicílio</li>
            <li>Aplicação de vacinas em domicílio</li>
            <li>Identificador/localizador de animais</li>
            <li>Hospedagem do pet em caso de doença ou acidente</li>
          </ul>
        </>
      ),
      image: "https://cdn.pixabay.com/photo/2017/11/30/16/45/dog-2988868_960_720.jpg",
      alt: "Assistência Pet",
    },
  ];

  return (
    <>
      <Header />
      <MainContent>
        <HeroSection>
          <HeroImage src="residential_insurance_image02.jpg" alt="Seguro Residencial" />
          <HeroText>
            <h1>Seguro Residencial</h1>
            <p>
              Proteja sua casa e sua tranquilidade. Descubra a importância de contar com um seguro que cobre desde danos estruturais até assistência em emergências do dia a dia.
            </p>
            <CallToAction href="https://wa.me/5521978385755" target="_blank" rel="noopener noreferrer">
              Fale com um consultor agora
            </CallToAction>
          </HeroText>
        </HeroSection>

        {sections.map((section, index) => (
          <Section key={index}>
            <h2>{section.title}</h2>
            <ImageTextLayout reverse={index % 2 !== 0}>
              <Content>{section.content}</Content>
              <Image src={section.image} alt={section.alt} />
            </ImageTextLayout>
          </Section>
        ))}

        <HighlightedSection>
          <h2>Valor Médio do Seguro Residencial</h2>
          <p>
            Em média, um seguro residencial custa entre <strong>R$ 456,55</strong> e <strong>R$ 820,36</strong> por ano.
          </p>
          <p>É um investimento acessível em relação aos custos dos transtornos que podem ser evitados.</p>
          <CenteredCallToAction href="https://wa.me/5521978385755" target="_blank" rel="noopener noreferrer">
            Fale com um consultor agora
          </CenteredCallToAction>
        </HighlightedSection>
      </MainContent>
    </>
  );
}

export default SeguroResidencial;

// Estilização
const MainContent = styled.main`
  background: #f9f9f9;
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #00a16f;
  color: white;
  text-align: center;
  padding: 20px;
`;

const HeroImage = styled.img`
  width: 80%;
  height: auto;
  max-height: 250px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid white;
  }
`;

const Image = styled.img`
  flex: 1;
  max-width: 400px;
  object-fit: cover;
  border-radius: 8px;

  @media (max-width: 768px) {
    height: 150px; /* Alinha à meta da Assistências Básicas */
    max-width: 100%;
    object-fit: cover;
  }
`;

const HeroText = styled.div`
  max-width: 800px;
  text-align: left;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 1.1rem;
    }
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: inherit; /* Isso garante que o link use a cor do texto */
  
  &:hover {
    text-decoration: none; /* Garantia extra */
  }
`;

// Substitua seus links
const CallToAction = styled(Link)`
  background: #ff6600;
  color: white;
  padding: 15px 30px;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: #ff8c42;
  }
`;


const CenteredCallToAction = styled(CallToAction)`
  display: block;
  margin: 20px auto;
`;

const Section = styled.section`
  background: white;
  margin: 20px auto;
  padding: 40px 20px;
  max-width: 1200px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 1.6rem;
    margin-bottom: 20px;
    color: #064420;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 15px;
    color: #333;
  }
`;

const HighlightedSection = styled(Section)`
  background: #f9fdf9;
  border: 1px solid #dfe7df;
  text-align: center;

  h2 {
    font-size: 1.5rem;
    color: #064420;
  }

  p {
    font-size: 1.2rem;
    color: #333;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;

const ImageTextLayout = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  flex: 1;

  ul {
    list-style: none;
    padding: 0;
  }

  li::before {
    content: "• ";
    color: #ff6600;
  }

  li {
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;
