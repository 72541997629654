import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./SimularCredito.css";
import Header from "../components/Header"; // Importação do Header

function SimularCredito() {
  const [formData, setFormData] = useState({
    product: "Crédito",
    fullName: "",
    email: "",
    cpfOrCnpj: "CPF",
    identifier: "",
    birthDate: "",
    income: "",
    maritalStatus: "",
    employmentStatus: "",
    phone: "",
    guarantee: "",
    value: "",
    prazoTerm: "Prazo para pagamento (meses)",
    paymentTerm: "",
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCpfCnpjChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      cpfOrCnpj: e.target.value,
      identifier: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");

    emailjs
      .send(
        "service_r9ciohd",
        "template_q4glf16",
        { ...formData },
        "E8RszPUuHa1xdpkxd"
      )
      .then(
        () => {
          setSuccessMessage(
            "Sua solicitação foi enviada com sucesso! Em breve entraremos em contato."
          );
          setFormData({
            product: "Crédito",
            fullName: "",
            email: "",
            cpfOrCnpj: "CPF",
            identifier: "",
            birthDate: "",
            income: "",
            maritalStatus: "",
            employmentStatus: "",
            phone: "",
            guarantee: "",
            value: "",
            prazoTerm: "Prazo para pagamento (meses)",
            paymentTerm: "",
          });
        },
        (error) => {
          console.error("Erro ao enviar o formulário:", error);
          alert("Erro ao enviar o formulário. Tente novamente.");
        }
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Header /> {/* Adicionando o Header */}
      <section id="simular-credito" className="form-container">
        <h2>Simule seu Crédito</h2>
        <p>Preencha os dados abaixo para receber sua cotação personalizada:</p>

        <form id="simulacao-form" onSubmit={handleSubmit}>
          <div className="form-row destaque">
            <div className="form-group">
              <label htmlFor="value">Valor necessário (R$)</label>
              <input
                type="number"
                id="value"
                name="value"
                placeholder="Ex: 10.000"
                value={formData.value}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="paymentTerm">Prazo para pagamento (meses)</label>
              <input
                type="number"
                id="paymentTerm"
                name="paymentTerm"
                placeholder="Ex: 24"
                value={formData.paymentTerm}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="fullName">Nome completo</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                placeholder=""
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">E-mail</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder=""
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Solicitante</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="cpfOrCnpj"
                    value="CPF"
                    checked={formData.cpfOrCnpj === "CPF"}
                    onChange={handleCpfCnpjChange}
                    required
                  />
                  Pessoa Física
                </label>
                <label>
                  <input
                    type="radio"
                    name="cpfOrCnpj"
                    value="CNPJ"
                    checked={formData.cpfOrCnpj === "CNPJ"}
                    onChange={handleCpfCnpjChange}
                    required
                  />
                  Pessoa Jurídica
                </label>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="identifier">
                {formData.cpfOrCnpj === "CPF" ? "CPF" : "CNPJ"}
              </label>
              <input
                type="text"
                id="identifier"
                name="identifier"
                placeholder=""
                value={formData.identifier}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="birthDate">Data de nascimento</label>
              <input
                type="date"
                id="birthDate"
                name="birthDate"
                value={formData.birthDate}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="income">Renda mensal</label>
              <input
                type="number"
                id="income"
                name="income"
                placeholder=""
                value={formData.income}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row garantia-group">
            <div className="form-group">
              <label>Garantia</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="guarantee"
                    value="Carro"
                    checked={formData.guarantee === "Carro"}
                    onChange={handleChange}
                    required
                  />
                  Carro
                </label>
                <label>
                  <input
                    type="radio"
                    name="guarantee"
                    value="Imóvel"
                    checked={formData.guarantee === "Imóvel"}
                    onChange={handleChange}
                    required
                  />
                  Imóvel
                </label>
              </div>
            </div>
          </div>

          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
          <button className="btn-enviar" type="submit" disabled={loading}>
            {loading ? "Enviando..." : "Enviar"}
          </button>
        </form>
      </section>
    </>
  );
}

export default SimularCredito;
