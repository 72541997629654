import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Controle do menu responsivo

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <header className="header-container">
      <div className="logo-container">
        <NavLink to="/" exact>
          <img src="/logo.png" alt="Logo Capitus" className="logo" />
        </NavLink>
      </div>

      {/* Botão do Menu Hamburguer */}
      <button
        className="menu-toggle"
        onClick={toggleMenu}
        aria-label="Abrir ou fechar o menu"
      >
        ☰
      </button>

      {/* Menu de Navegação */}
      <nav className={`navbar-container ${isMenuOpen ? "open" : ""}`}>
        <ul className="navbar">
          <li>
            <NavLink
              to="/quem-somos"
              activeClassName="active"
              exact
              onClick={() => setIsMenuOpen(false)} // Fecha o menu no clique
            >
              Quem Somos
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/solucoes"
              activeClassName="active"
              exact
              onClick={() => setIsMenuOpen(false)}
            >
              Soluções Financeiras
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/creditos"
              activeClassName="active"
              exact
              onClick={() => setIsMenuOpen(false)}
            >
              Simular Crédito
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/seguros"
              activeClassName="active"
              exact
              onClick={() => setIsMenuOpen(false)}
            >
              Cotação de Seguros
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contato"
              activeClassName="active"
              exact
              onClick={() => setIsMenuOpen(false)}
            >
              Contato
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
