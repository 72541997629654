import React, { useState } from "react";
import "./SolucoesFinanceiras.css"; // Arquivo de estilos
import { motion } from "framer-motion"; // Biblioteca de animação opcional
import Header from "../components/Header"; // Importação do Header

// Lista de soluções financeiras atualizada
const solutions = [
  // Serviços exclusivos para PF (Pessoa Física)
  {
    title: "Limpa Nome",
    description: "Recupere sua credibilidade financeira com nosso serviço de quitação de dívidas.",
    icon: "🧹",
    tipo: "PF",
    link: "/limpa-nome",
  },
  {
    title: "FGTS",
    description: "Aproveite o saque do FGTS para melhorar sua situação financeira.",
    icon: "💼",
    tipo: "PF",
    link: "/cotacao/fgts",
  },
  // Serviços exclusivos para PJ (Pessoa Jurídica)
  {
    title: "Capital de Giro",
    description: "Obtenha recursos financeiros para expandir ou manter sua empresa em operação.",
    icon: "🏢",
    tipo: "PJ",
    link: "/cotacao/capital-de-giro",
  },
  {
    title: "Energia Solar",
    description: "Sistemas de energia solar com financiamento facilitado para sua empresa.",
    icon: "🌞",
    tipo: "PJ",
    link: "/cotacao/energia-solar",
  },
    // Seguro Residencial (novo produto)
    {
      title: "Seguro Residencial",
      description: "Proteja sua casa com coberturas contra incêndio, roubo, danos elétricos e muito mais.",
      icon: "🏠",
      tipo: "Ambos",
      link: "/seguro-residencial",
    },
  // Serviços para Ambos
  {
    title: "Plano Odontológico",
    description: "Cuide da sua saúde bucal com nossos planos odontológicos abrangentes.",
    icon: "🦷",
    tipo: "Ambos",
    link: "/cotacao/plano-odontologico",
  },
  {
    title: "Plano de Saúde",
    description: "Garanta o melhor cuidado médico com nossos planos de saúde personalizados.",
    icon: "🏥",
    tipo: "Ambos",
    link: "/cotacao/plano-de-saude",
  },
  {
    title: "Financiamentos e Créditos",
    description: "Soluções de crédito para atender às suas necessidades financeiras.",
    icon: "💰",
    tipo: "Ambos",
    link: "/cotacao/financiamentos-creditos",
  },
  {
    title: "Consórcios",
    description: "Planeje a aquisição de bens e serviços com nossos consórcios flexíveis.",
    icon: "🛒",
    tipo: "Ambos",
    link: "/cotacao/consorcios",
  },
  {
    title: "Seguros",
    description: "Proteja seu patrimônio com nossos seguros personalizados.",
    icon: "🛡️",
    tipo: "Ambos",
    link: "/cotacao/seguros",
  },
  {
    title: "Planejamento Financeiro",
    description: "Organize suas finanças e alcance seus objetivos com soluções feitas para você.",
    icon: "📊",
    tipo: "Ambos",
    link: "/cotacao/planejamento-financeiro",
  },
];

const SolucoesFinanceiras = () => {
  const [filter, setFilter] = useState("Todos");

  const handleFilterClick = (tipo) => {
    setFilter(tipo);
  };

  const filteredSolutions = solutions
    .filter((solution) => {
      if (filter === "Todos") return true;
      if (filter === "PF") return solution.tipo === "PF" || solution.tipo === "Ambos";
      if (filter === "PJ") return solution.tipo === "PJ" || solution.tipo === "Ambos";
      return false;
    })
    .sort((a, b) => {
      if (filter === "Todos") return 0;
      if (a.tipo === filter && b.tipo !== filter) return -1;
      if (a.tipo !== filter && b.tipo === filter) return 1;
      return 0;
    });

  return (
    <>
      {/* Renderização do Header */}
      <Header />

      {/* Conteúdo principal */}
      <div className="solutions-container">
        <h1 className="solutions-title">Soluções Financeiras Sob Medida</h1>
        <p className="solutions-subtitle">
          Descubra as opções que melhor se adaptam às suas necessidades e objetivos.
        </p>

        {/* Filtros */}
        <div className="filter-buttons">
          <button
            className={`filter-button ${filter === "Todos" ? "active" : ""}`}
            onClick={() => handleFilterClick("Todos")}
          >
            Todos
          </button>
          <button
            className={`filter-button ${filter === "PF" ? "active" : ""}`}
            onClick={() => handleFilterClick("PF")}
          >
            Para mim
          </button>
          <button
            className={`filter-button ${filter === "PJ" ? "active" : ""}`}
            onClick={() => handleFilterClick("PJ")}
          >
            Para meu negócio
          </button>
        </div>

        <div className="solutions-grid">
          {filteredSolutions.map((solution, index) => (
            <div key={index} className="solution-card">
              <div className="solution-icon">{solution.icon}</div>
              <h2 className="solution-title">{solution.title}</h2>
              <p className="solution-description">{solution.description}</p>
              <a href={solution.link} className="quote-button">
                Saiba Mais
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SolucoesFinanceiras;
