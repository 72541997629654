import React from "react";
import "./TermoDeUso.css"; // Importa o estilo correspondente

const TermoDeUso = () => {
  return (
    <div className="termo-container">
      <h1>Termos de Uso</h1>
      <p>
        Este Termo de Uso regula o acesso e a utilização do site da <strong>Capitus</strong>,
        empresa especializada em soluções financeiras personalizadas. Ao utilizar este site, você
        concorda com os termos aqui descritos. Caso não concorde, recomendamos interromper o uso
        imediatamente.
      </p>

      <h2>1. Finalidade do site</h2>
      <p>
        O site da Capitus tem como objetivo fornecer informações e permitir a solicitação de
        cotações de produtos financeiros, como empréstimos, seguros e financiamentos. As informações
        disponibilizadas não constituem oferta ou garantia de aprovação dos serviços.
      </p>

      <h2>2. Uso do site</h2>
      <p>
        O usuário deve utilizar o site de forma ética e responsável, comprometendo-se a fornecer
        informações verdadeiras e completas ao preencher formulários ou enviar solicitações. É
        proibido qualquer uso do site para fins ilícitos ou que violem os direitos de terceiros.
      </p>

      <h2>3. Papel da Capitus</h2>
      <p>
        A Capitus atua como correspondente bancário, intermediando a comunicação e a negociação com
        instituições financeiras e seguradoras parceiras. A decisão final sobre aprovação de
        serviços financeiros cabe exclusivamente às instituições envolvidas.
      </p>

      <h2>4. Limitação de responsabilidade</h2>
      <p>
        A Capitus não se responsabiliza por decisões tomadas pelas instituições financeiras parceiras
        nem pela disponibilidade ou exatidão das informações apresentadas no site. O uso das
        informações é de total responsabilidade do usuário.
      </p>

      <h2>5. Propriedade intelectual</h2>
      <p>
        Todo o conteúdo deste site, incluindo textos, imagens, logotipos e marcas, é protegido por
        leis de propriedade intelectual. É proibida a reprodução, distribuição ou modificação desse
        conteúdo sem autorização prévia da Capitus.
      </p>

      <h2>6. Privacidade</h2>
      <p>
        O uso deste site está sujeito à nossa <a href="/politica-privacidade">Política de Privacidade</a>,
        que descreve como coletamos, utilizamos e protegemos seus dados pessoais. Recomendamos a sua
        leitura.
      </p>

      <h2>7. Alterações nos Termos</h2>
      <p>
        A Capitus reserva-se o direito de alterar este Termo de Uso a qualquer momento. As
        modificações entrarão em vigor assim que publicadas no site. É responsabilidade do usuário
        revisar regularmente o Termo de Uso.
      </p>

      <h2>8. Legislação aplicável</h2>
      <p>
        Este Termo de Uso é regido pela legislação brasileira. Quaisquer disputas relacionadas ao seu
        uso serão resolvidas no foro da comarca da sede da Capitus.
      </p>

      <h2>9. Contato</h2>
      <p>
        Para dúvidas ou solicitações relacionadas a este Termo de Uso, entre em contato conosco pelos
        canais abaixo:
      </p>
      <ul>
        <li><strong>E-mail:</strong> contato@capitus.com.br</li>
        <li><strong>Telefone:</strong> (11) 93410-8166</li>
        <li><strong>WhatsApp:</strong> (21) 97838-5755</li>
      </ul>
    </div>
  );
};

export default TermoDeUso;
