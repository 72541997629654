import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fontsource/poppins"; // Importação da fonte Poppins

const Parceiros = () => {
  const texts = [
    "C6 Bank",
    "Inter",
    "Banco BV",
    "Santander",
    "Prudential",
    "Bradesco",
    "Itaú",
    "Porto",
    "Banco Sofisa",
    "Banco Daycoval",
    "Rodobens",
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ width: "90%", margin: "30px auto", textAlign: "lefet", fontFamily: "'Poppins', sans-serif" }}>
      <h3 style={{ fontSize: "1.1em", lineHeight: "1.5", marginBottom: "20px", fontWeight: 100, color: "#f4f4f4" }}>
        Conectamos você às melhores soluções financeiras do mercado. Nossa rede
        conta com mais de 20 instituições financeiras parceiras, oferecendo uma
        ampla gama de produtos, desde crédito e financiamentos até seguros e
        planos de saúde.
      </h3>
      <Slider {...settings}>
        {texts.map((text, index) => (
          <div key={index}>
            <div
              style={{
                margin: "0 10px",
                padding: "20px",
                background: "#f9f9f9",
                border: "1px solid #ddd",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "#666",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                {text}
              </p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Parceiros;
